<template>

  <div class="page page-xProgrammatic-all-campaign-import">

    <Loading :value="loading" color="#f0f0f0" />

    <v-dialog :value="dialogCampaignJob" width="720px" persistent>
      <v-card class="height-min-50vh">

        <v-system-bar dark color="primary">
          <v-icon class="mr-1">mdi-plus</v-icon>
          <small ><strong class="text-uppercase">{{ $t('CAMPAIGN.FORM_TITLE_JOB_IMPORT') }}</strong></small>
          <v-spacer/>
          <v-icon
            small
            class="clickable"
            @click="dialogCampaignJob = false"
          >
            mdi-close
          </v-icon>
        </v-system-bar>

        <List
          background="255,255,255"
          :items="jobItems"
          :count="jobCount"
          :offset="jobOffset"
          :limit="jobLimit"
          :pagination="true"
          :loading="loading_dialog"
          :selected="selectedJobs"
          @applyFilters="applyJobFilters"
          @resetFilters="resetJobFilters"
          @select="selectJobItems"
          @page="changeJobPage"
          @click="selectJobItem"
          @reload="searchJobItems"
        >
          <template v-slot:header="">
            <div class="col-6">
              <v-text-field
                ref="where.title"
                rounded
                hide-details
                outlined
                dense
                clearable
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('COMMON.SEARCH')"
                @keyup="searchJobItems"
                @click:clear="searchJobItems"
              />
            </div>
          </template>

          <template v-slot:filters="">
            <div class="pa-4">
            </div>
          </template>

          <template v-slot:nav="">
            <!-- <v-btn icon @click="searchJobItems"><v-icon>mdi-reload</v-icon></v-btn> -->
          </template>

          <template v-slot:selections="">
            <v-col cols="12" class="pt-0 pl-0">
              <div><small><strong>{{ $tc('COMMON.SELECTED', +selectedJobs.length) }}</strong></small></div>

              <v-btn-toggle dark rounded>
                <v-btn
                small
                rounded
                depressed
                color="primary"
                @click="selectAllJobPages"
              >
                <v-icon size="20" class="mr-2">mdi-playlist-plus</v-icon>
                {{ $t('COMMON.SELECT_ALL_PAGES') }}
                </v-btn>

                <v-btn
                small
                rounded
                depressed
                color="primary"
                @click="importJobs"
                >
                  <v-icon size="20" class="mr-2">mdi-plus</v-icon>
                  {{ $t('COMMON.IMPORT') }}
                </v-btn>

                <v-btn
                small
                rounded
                depressed
                color="error"
                @click="removeJobs"
                >
                  <v-icon size="20" class="mr-2">mdi-minus</v-icon>
                  {{ $t('COMMON.REMOVE') }}
                </v-btn>

                <v-btn
                  small
                  rounded
                  depressed
                  color="secondary custom black--text"
                  @click="selectJobItems([])"
                >
                  <v-icon size="20" class="mr-2" color="black">mdi-close</v-icon>
                  {{ $t('COMMON.CANCEL') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </template>

          <template v-slot:item-list="item">

            <v-list-item-content>
              <v-list-item-title class="primary--text">
                <strong>{{item.title}}</strong>
                <v-icon
                v-if="item.parent_id"
                :title="item.parent_id"
                color="grey"
                class="ml-2"
                size="14"
                >
                {{ $t('COMMON.DUPLICATE_ICON') }}
              </v-icon>

              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="mr-2" v-if="item.company_title || item.company"><v-icon class="mr-1" size="12">{{$t('COMPANY.ICON')}}</v-icon>{{item.company_title || item.company}}</span>
                <span class="mr-2" v-if="item.location"><v-icon class="mr-1" size="12">mdi-map-marker</v-icon>{{ item.location_city + ', ' + item.location_city_code }}</span>
                <span class="mr-2" v-if="item.xtramile_sector_id"><v-icon class="mr-1" size="12">mdi-bookmark</v-icon>{{ $t('JOB.SECTOR.' + item.xtramile_sector_id) }}</span>

              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <div>

                <v-btn
                v-if="item.campaign_jobs.length > 0"
                fab
                x-small
                depressed
                color="error"
                class="ml-2"
                @click.stop="removeJob(item)"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>

                <v-btn
                v-else
                fab
                x-small
                depressed
                color="primary"
                class="ml-2"
                @click.stop="importJob(item)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

              </div>
            </v-list-item-action>
          </template>
        </List>

    </v-card>
    </v-dialog>


    <v-dialog :value="dialogCampaignFeed" width="720px" persistent>
      <v-card class="height-min-50vh">

        <v-system-bar dark color="primary">
          <v-icon class="mr-1">mdi-plus</v-icon>
          <small ><strong class="text-uppercase">{{ $t('CAMPAIGN.FORM_TITLE_FEED_IMPORT') }}</strong></small>
          <v-spacer/>
          <v-icon
            small
            class="clickable"
            @click="dialogCampaignFeed = false"
          >
            mdi-close
          </v-icon>
        </v-system-bar>

        <List
          background="255,255,255"
          :items="feedItems"
          :count="feedCount"
          :offset="feedOffset"
          :limit="feedLimit"
          :pagination="true"
          :loading="loading_dialog"
          :selected="selectedFeeds"
          @applyFilters="applyFeedFilters"
          @resetFilters="resetFeedFilters"
          @select="selectFeedItems"
          @page="changeFeedPage"
          @click="selectFeedItem"
          @reload="searchFeedItems"
        >
        <template v-slot:header="">
          <div class="col-6">
            <v-text-field
              ref="where.title"
              rounded
              hide-details
              outlined
              dense
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('COMMON.SEARCH')"
              @keyup="searchFeedItems"
              @click:clear="searchFeedItems"
            />
          </div>
        </template>

        <template v-slot:filters="">
          <div class="pa-4">
          </div>
        </template>

        <template v-slot:nav="">
          <!-- <v-btn icon @click="searchFeedItems"><v-icon>mdi-reload</v-icon></v-btn> -->
        </template>

        <template v-slot:selections="">
          <v-col cols="12" class="pt-0 pl-0">
            <div><small><strong>{{ $tc('COMMON.SELECTED', +selectedFeeds.length) }}</strong></small></div>

            <v-btn-toggle dark rounded>
              <v-btn
              small
              rounded
              depressed
              color="primary"
              @click="selectAllFeedPages"
            >
              <v-icon size="20" class="mr-2">mdi-playlist-plus</v-icon>
              {{ $t('COMMON.SELECT_ALL_PAGES') }}
              </v-btn>

              <v-btn
                small
                rounded
                depressed
                color="primary"

                @click="importFeeds"
              >
                <v-icon size="20" class="mr-2">mdi-plus</v-icon>
                {{ $t('COMMON.IMPORT') }}
              </v-btn>

              <v-btn
                small
                rounded
                depressed
                color="error"

                @click="removeJobs"
                >
                <v-icon size="20" class="mr-2">mdi-minus</v-icon>
                {{ $t('COMMON.REMOVE') }}
              </v-btn>

              <v-btn
                small
                rounded
                depressed
                color="secondary custom black--text"
                @click="selectFeedItems([])"
              >
                <v-icon size="20" class="mr-2" color="black">mdi-close</v-icon>
                {{ $t('COMMON.CANCEL') }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </template>

        <template v-slot:item-list="item">

          <v-list-item-content>
            <v-list-item-title class="primary--text">
              <strong>{{item.title}}</strong>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <div>

              <v-btn
              v-if="item.campaign_feeds.length > 0"
              fab
              x-small
              depressed
              color="error"
              class="ml-2"
              @click.stop="removeFeed(item)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>

              <v-btn
              v-else
              fab
              x-small
              depressed
              color="primary"
              class="ml-2"
              @click.stop="importFeed(item)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </template>
      </List>

    </v-card>
    </v-dialog>

    <v-row class="ma-0 ml-4 mr-4">
      <v-col cols="6">
        <v-card flat>

          <v-toolbar large flat color="transparent">
            <v-toolbar-title>
              <v-icon size="24" class="mb-1 mr-2" color="primary">{{$t('JOB.ICON')}}</v-icon>
              <span>{{$t('JOB.TITLES')}}</span>
              <small class="grey--text ml-2">{{$tc('JOB.TITLE_COUNT',+countCampaignJob)}}</small>
            </v-toolbar-title>
            <v-spacer />
            <v-btn rounded depressed color="primary" @click="dialogCampaignJob = true"><v-icon class="mr-2">mdi-plus</v-icon>Importer des offres</v-btn>
          </v-toolbar>
          <v-divider />
          <v-list color="transparent" dense class="list-small">
            <template v-for="(item,i) in campaignJobItems">
              <v-list-item :key="'jo'+i">
                <v-list-item-content>
                  <v-list-item-title>{{item.job.title}}</v-list-item-title>
                  <v-list-item-subtitle>
                    <strong v-if="item.feed && item.feed.title">{{item.feed.title}}</strong>
                    <span class="grey--text">{{$moment(item.created_at).fromNow()}}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>


      <v-col cols="6">
        <div>
          <h3 class="primary--text">
            <v-icon size="24" class="mb-1 mr-2" color="primary">{{$t('FEED.ICON')}}</v-icon>
            <span>{{$t('FEED.TITLES')}}</span>
          </h3>
          <p class="grey--text">{{$tc('FEED.TITLE_COUNT',+countCampaignFeed)}}</p>
          <v-btn rounded depressed color="primary" @click="dialogCampaignFeed = true"><v-icon class="mr-2">mdi-plus</v-icon>Importer des flux</v-btn>
        </div>

        <p class="grey--text mt-4 mb-0">Derniers imports:</p>
        <v-list color="transparent" dense class="list-small">
          <template v-for="(item,i) in campaignFeedItems">
            <v-list-item :key="'fe'+i" class="pa-0">
              <v-list-item-title>{{item.feed.title}}<span class="grey--text ml-2">{{$moment(item.created_at).fromNow()}}</span></v-list-item-title>
            </v-list-item>
          </template>
        </v-list>

      </v-col>

    </v-row>


  </div>
</template>

<script>

import Loading from '@/components/Loading'
import List from '@/components/List'

export default {
  name: 'PageXProgrammaticAllCampaignImport',
  components: {
    List,
    Loading,
  },
  data: () => ({
    // job list
    dialogCampaignJob: false,
    jobItems: [],
    jobCount: 0,
    jobPage: 0,
    jobOffset: 0,
    jobLimit: 100,
    selectedJobs: [],
    // feed list
    dialogCampaignFeed: false,
    feedItems: [],
    feedCount: 0,
    feedPage: 0,
    feedOffset: 0,
    feedLimit: 100,
    selectedFeeds: [],
    //
    loading: false,
    loading_dialog: false,
    countCampaignFeed: 0,
    countCampaignJob: 0,
    campaignJobItems: [],
    campaignFeedItems: [],
  }),
  watch: {
    dialogCampaignJob (n) {
      if (n) this.loadJobItems()
      else this.loadAllItems()
    },
    dialogCampaignFeed (n) {
      if (n) this.loadFeedItems()
      else this.loadAllItems()
    },
  },
  computed: {
    campaignId () {
      return this.$router.currentRoute.params.id || undefined
    },
  },
  mounted () {
    this.loadAllItems()
  },
  methods: {

    // JOBS
    applyJobFilters () {},
    resetJobFilters () {},

    selectJobItems (arr) {
      this.selectedJobs = arr
    },

    searchJobItems (element) {
      this.selectedJobs = []
      this.loading_dialog = true
      // debounce request
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadJobItems()
      }, 100)
    },

    loadJobItems (options = {}) {
      this.loading_dialog = true
      this.$queryFilters().save()
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        mode: 'list',
        page: this.jobPage || 1,
        limit: this.jobLimit,
      }

      const query = this.$queryFormat({
        where: {status: 1, campaign_jobs: {campaign_id: this.campaignId}, feed: true},
        options: Object.assign({}, defaultOptions, {options}),
      })
      this.$emit('childData', {queryList: query} )
      this.$services.api_programmatic.job.search(query)
        .then(response => {
          if (response.data) {
            this.jobItems = response.data.items
            this.jobCount = response.data.count
            this.jobOffset = response.data.offset
            this.jobLimit = response.data.limit
          } else {
            this.jobItems = []
            this.jobCount = 0
            this.jobOffset = 0
            this.jobLimit = 0
          }
          this.updateSelectedJob()
        })
        .finally(() => {
          this.loading_dialog = false
        })
    },

    changeJobPage (page) {
      this.jobPage = +page
      this.searchJobItems()
    },

    updateSelectedJob () {
      for (let i = 0, lenI = this.selectedJobs.length; i < lenI; i++) {
        const a = this.selectedJobs[i]
        for (let j = 0, lenJ = this.jobItems.length; j < lenJ; j++) {
          const b = this.jobItems[j]
          if (a.id === b.id) {
            this.selectedJobs[i] = b
            break
          }
        }
      }
    },

    selectJobItem (item) {
      for (let i=0, len = this.selectedJobs.length; i < len; i++) {
        if (this.selectedJobs[i].id === item.id) return this.selectedJobs.splice(i,1)
      }
      return this.selectedJobs.push(item)
    },

    selectAllJobPages (options) {
      this.loading_dialog = true
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        attributes: ['id', 'updated_at'],
        limit: 100000,
      }
      const query = this.$queryFormat({
        where: {status: 1},
        options: Object.assign({}, defaultOptions, {options}),
      })

      this.$services.api_programmatic.job.search(query)
        .then(response => {
          if (response.data) {
            this.selectedJobs = response.data
          }
        })
        .finally(() => {
          this.loading_dialog = false
        })

    },

    loadAllItems() {
      this.loadCampaignFeedItems()
      this.loadCampaignJobItems()
    },

    importJob (item) {
      this.loading_dialog = true
      this.$services.api_programmatic.campaign_job.create({values: {job_id: item.id, campaign_id: this.campaignId}, options: {triggerCampaignRules: true}})
        .then(response => {
          this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
          this.loadJobItems()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading_dialog = false
        })
    },
    importJobs () {
      this.loading_dialog = true
      const items = this.selectedJobs.map(item => ({job_id: item.id, campaign_id: this.campaignId}))
      this.$services.api_programmatic.campaign_job.create({values:items, options: {triggerCampaignRules: true}})
      .then(response => {
        // this.loadAllItems()
        this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
        this.loadJobItems()
      })
      .catch(e => {
        this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      })
      .finally(() => {
        this.loading_dialog = false
      })
    },

    removeJob (item) {
      this.loading_dialog = true
      this.$services.api_programmatic.campaign_job.remove({where:{job_id: item.id, campaign_id: this.campaignId}})
      .then(response => {
        // this.loadAllItems()
        this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
        this.loadJobItems()
      })
      .catch(e => {
        this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      })
      .finally(() => {
        this.loading_dialog = false
      })
    },

    removeJobs () {
      this.loading_dialog = true
      const items = this.selectedJobs.map(item => ({job_id: item.id, campaign_id: this.campaignId}))
      this.$services.api_programmatic.campaign_job.remove({where:items, options: {limit:items.length}})
      .then(response => {
        this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
        this.loadJobItems()
      })
      .catch(e => {
        this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      })
      .finally(() => {
        this.loading_dialog = false
      })
    },


    // FEEDS

    applyFeedFilters () {},
    resetFeedFilters () {},

    selectFeedItems (arr) {
      this.selectedFeeds = arr
    },

    searchFeedItems (element) {
      this.selectedFeeds = []
      this.loading_dialog = true
      // debounce request
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadFeedItems()
      }, 100)
    },

    loadFeedItems (options = {}) {
      this.loading_dialog = true
      this.$queryFilters().save()
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        mode: 'list',
        page: this.feedPage || 1,
        limit: this.feedLimit,
      }

      const query = this.$queryFormat({
        where: {status: 1, campaign_feeds: {campaign_id: this.campaignId}},
        options: Object.assign({}, defaultOptions, {options}),
      })
      this.$emit('childData', {queryList: query} )
      this.$services.api_programmatic.feed.search(query)
        .then(response => {
          if (response.data) {
            this.feedItems = response.data.items
            this.feedCount = response.data.count
            this.feedOffset = response.data.offset
            this.feedLimit = response.data.limit
          } else {
            this.feedItems = []
            this.feedCount = 0
            this.feedOffset = 0
            this.feedLimit = 0
          }
          this.updateSelectedFeed()
        })
        .finally(() => {
          this.loading_dialog = false
        })
    },

    changeFeedPage (page) {
      this.feedPage = +page
      this.searchFeedItems()
    },

    updateSelectedFeed () {
      for (let i = 0, lenI = this.selectedFeeds.length; i < lenI; i++) {
        const a = this.selectedFeeds[i]
        for (let j = 0, lenJ = this.feedItems.length; j < lenJ; j++) {
          const b = this.feedItems[j]
          if (a.id === b.id) {
            this.selectedFeeds[i] = b
            break
          }
        }
      }
    },

    selectFeedItem (item) {
      for (let i=0, len = this.selectedFeeds.length; i < len; i++) {
        if (this.selectedFeeds[i].id === item.id) return this.selectedFeeds.splice(i,1)
      }
      return this.selectedFeeds.push(item)
    },

    selectAllFeedPages (options) {
      this.loading_dialog = true
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        attributes: ['id', 'updated_at'],
        limit: 100000,
      }
      const query = this.$queryFormat({
        where: {status: 1},
        options: Object.assign({}, defaultOptions, {options}),
      })

      this.$services.api_programmatic.feed.search(query)
        .then(response => {
          if (response.data) {
            this.selectedFeeds = response.data
          }
        })
        .finally(() => {
          this.loading_dialog = false
        })

    },

    importFeed (item) {
      this.loading_dialog = true
      this.$services.api_programmatic.campaign_feed.create({values: {feed_id: item.id, campaign_id: this.campaignId}, options: {triggerCampaignRules: true}})
        .then(response => {
          this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
          this.loadFeedItems()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading_dialog = false
        })
    },
    importFeeds () {
      this.loading_dialog = true
      const items = this.selectedFeeds.map(item => ({feed_id: item.id, campaign_id: this.campaignId, options: {triggerCampaignRules: true}}))
      this.$services.api_programmatic.campaign_feed.create({values:items})
      .then(response => {
        this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
        this.loadFeedItems()
      })
      .catch(e => {
        this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      })
      .finally(() => {
        this.loading_dialog = false
      })
    },

    removeFeed (item) {
      this.loading_dialog = true
      this.$services.api_programmatic.campaign_feed.remove({where:{feed_id: item.id, campaign_id: this.campaignId}})
      .then(response => {
        this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
        this.loadFeedItems()
      })
      .catch(e => {
        this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      })
      .finally(() => {
        this.loading_dialog = false
      })
    },

    removeFeeds () {
      this.loading_dialog = true
      const items = this.selectedFeeds.map(item => ({feed_id: item.id, campaign_id: this.campaignId}))
      this.$services.api_programmatic.campaign_feed.remove({where:items, options: {limit:items.length}})
      .then(response => {
        this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
        this.loadFeedItems()
      })
      .catch(e => {
        this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      })
      .finally(() => {
        this.loading_dialog = false
      })
    },



    // PAGE
    loadCampaignFeedItems () {
      this.loading = true
      this.$services.api_programmatic.campaign_feed.search({where: {feed:true, campaign_id: this.campaignId}, options: {limit: 10, mode:'list'}})
        .then(response => {
          if (response.data) {
            this.campaignFeedItems = response.data.items
            this.countCampaignFeed = response.data.count
          } else {
            this.campaignFeedItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadCampaignJobItems () {
      this.loading = true
      this.$services.api_programmatic.campaign_job.search({where: {job:{draft:false}, campaign_id: this.campaignId}, options: {limit: 10, mode:'list'}})
        .then(response => {
          if (response.data) {
            this.campaignJobItems = response.data.items
            this.countCampaignJob = response.data.count
          } else {
            this.campaignJobItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    }

  }
}
</script>
